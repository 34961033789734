const FacebookShare = ({ width, height, color, ...props }) => (
  <svg
    width={width || '22'}
    height={height || '22'}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.5 11.0635C21.5 5.26504 16.7984 0.563477 11 0.563477C5.20156 0.563477 0.5 5.26504 0.5 11.0635C0.5 16.3041 4.33906 20.648 9.35937 21.4364V14.0996H6.69266V11.0635H9.35937V8.75019C9.35937 6.1191 10.9273 4.66457 13.3255 4.66457C14.4744 4.66457 15.6762 4.86988 15.6762 4.86988V7.4541H14.3516C13.048 7.4541 12.6402 8.26316 12.6402 9.09473V11.0635H15.552L15.087 14.0996H12.6406V21.4374C17.6609 20.6494 21.5 16.3055 21.5 11.0635Z"
      fill={color || '#120232'}
    />
  </svg>
);

export default FacebookShare;
