import styled from '@emotion/styled';
import { setMargin } from '../../helpers';

export const DateWrapper = styled.div`
  ${setMargin};
  display: flex;
  flex-direction: column;
`;

export const DateSelectorWrapper = styled.div`
  .ant-select-focused .ant-select-selector {
    box-shadow: none !important;
    border: 2px solid ${({ theme }) => theme.colors.tertiaryMain} !important;
  }
`;
