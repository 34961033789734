const TikTok = ({ width, height, color, ...props }) => (
  <svg
    width={width || '33'}
    height={height || '38'}
    viewBox="0 0 35 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.383 0C23.9988 5.29246 26.9542 8.4478 32.0928 8.78348V14.7361C29.1149 15.027 26.5064 14.0536 23.4726 12.2185V23.3517C23.4726 37.4948 8.04583 41.9145 1.84377 31.7772C-2.14166 25.2539 0.298858 13.8074 13.0836 13.3486V19.6258C12.1096 19.7824 11.0685 20.0286 10.1169 20.3531C7.27337 21.3153 5.66128 23.1168 6.10908 26.2945C6.9711 32.3814 18.1438 34.1828 17.2146 22.2888V0.0111891H23.383V0Z"
      fill={color || 'white'}
    />
  </svg>
);

export default TikTok;
