import { Cascader, ConfigProvider } from 'antd';
import { Typography as T, Grid } from '../../components';
import { GENERAL } from '../../constants/nav-routes.js';
import { useHistory } from 'react-router-dom';
import * as S from './style.js';
import { Link } from '../../components/Typography';
import { pathForToday } from '../../helpers';
const { Col, Row } = Grid;

const DatePage = () => {
  const history = useHistory();

  const options = Array(12)
    .fill(0)
    .map((_, m) => {
      let month = new Date(2020, m + 1, 0); // Use leap year for full day options
      let days = Array(month.getDate())
        .fill(0)
        .map((_, i) => i + 1);
      let dayOptions = days.map((d) => {
        return { value: d, label: d };
      });
      dayOptions.push({ value: 0, label: 'Undated' });
      return {
        value: m + 1,
        label: month.toLocaleString('en-us', { month: 'long' }),
        children: dayOptions,
      };
    });

  const onChange = (value) => {
    let date = new Date(2020, value[0] - 1);
    let redirect = GENERAL.DATE.replace(
      ':monthName',
      `${date.toLocaleString('en-us', { month: 'long' }).toLowerCase()}`
    ).replace(':day', value[1]);
    history.push(redirect);
  };

  const dropdownRender = (menus) => <div id="selectDateDropdown">{menus}</div>;

  return (
    <S.DateWrapper>
      <T.H1>Date</T.H1>
      <Row>
        <Col w={[4, 12, 8]}>
          <T.P mt="6" color="neutral">
            Search for articles
            <Link to={pathForToday()} color="#6013FB">
              <strong> on this day </strong>
            </Link>
            or by selecting a date below
          </T.P>
        </Col>
      </Row>
      <Row mt="2">
        <Col w={[4, 4, 4]}>
          <ConfigProvider
            theme={{
              token: {
                lineWidth: 2,
                colorBorder: 'black',
                colorPrimaryHover: 'black',
                controlItemBgActive: 'rgba(0, 0, 0, 0.04)',
                borderRadiusLG: 0,
                controlHeightLG: 48,
                fontSizeLG: '20px',
              },
            }}
          >
            <S.DateSelectorWrapper>
              <Cascader
                style={{ width: '12em' }}
                size="large"
                placement="bottomLeft"
                options={options}
                onChange={onChange}
                placeholder="Select day"
                displayRender={(label) => label.join(' ')}
                dropdownRender={dropdownRender}
              />
            </S.DateSelectorWrapper>
          </ConfigProvider>
        </Col>
      </Row>
    </S.DateWrapper>
  );
};

export default DatePage;
