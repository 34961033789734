const renderTextPreview = (text, limit = 200) => {
  const htmlContent = text
    ?.split('>')
    ?.map((i) => i.split('<')[0])
    .filter((i) => !i.includes('=') && i.trim())
    .join('');

  if (htmlContent) {
    return htmlContent.length > limit
      ? `${htmlContent.substring(0, limit)}...`
      : `${htmlContent}...`;
  }
  if (text) {
    return text.length > limit
      ? `${text.substring(0, limit)}...`
      : `${text}...`;
  }
  return 'N/A';
};

export default renderTextPreview;
