import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import * as S from './style';
import { Typography as T, Grid } from '../../components';
import { Skeleton } from 'antd';

import { ReactComponent as Logo } from '../../components/assets/logo.svg';

import { getRandomArticleByDate } from '../../api-calls/Article';

import { GENERAL } from '../../constants/nav-routes';
import { renderTextPreview } from '../../helpers';

const { Row } = Grid;

const adjustBodyBasedOnTitleLength = (title) => {
  if (title?.length > 30) {
    return 150;
  } else return 200;
};

const ArticlePage = () => {
  const [data, setData] = useState({});
  const [bodyLength, setBodyLength] = useState(200);
  const [website, setWebsite] = useState('');
  const [loading, setLoading] = useState(false);
  const [pageError, setPageError] = useState('');

  const targetWebsite = `https://stories.workingclasshistory.com${GENERAL.ARTICLE}`;

  useEffect(() => {
    const getData = async () => {
      const date = dayjs().format('DD-MM-YYYY');
      try {
        setLoading(true);
        const { error, data } = await getRandomArticleByDate({ date });
        setBodyLength(adjustBodyBasedOnTitleLength(data?.title));
        setWebsite(
          targetWebsite
            .replace(':id', data.id)
            .replace(
              ':articleName',
              data.title.replace(/\s+|\/+/g, '-').toLowerCase()
            )
        );
        setData(data);
        setLoading(false);
        if (error) {
          setPageError(error.message);
        }
      } catch (error) {
        setPageError(error.message);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <S.Wrapper>
          <Skeleton key="article-skeleton" loading={loading} active></Skeleton>
        </S.Wrapper>
      ) : (
        <S.Wrapper bgImage={data?.media}>
          <Row jc="flex-end">
            <Logo style={{ width: '82px' }} />
          </Row>
          <T.H3 mb="3" mt="7" color="white">
            {data.title || 'N/A'}
          </T.H3>
          <T.P
            color="white"
            weight="light"
            size="small"
            ellipsis={{
              rows: 4,
              expandable: true,
              symbol: ' ',
            }}
            style={{ maxWidth: '790px' }}
          >
            {renderTextPreview(data?.description, bodyLength)}
          </T.P>
          <S.ReadMore href={website} target="_blank">
            <T.P size="small" weight="medium" underline color="white" mt="3">
              Continue reading...
            </T.P>
          </S.ReadMore>

          {pageError && (
            <T.P color="white" mt="5" mb="3">
              {pageError}
            </T.P>
          )}
        </S.Wrapper>
      )}
    </>
  );
};

export default ArticlePage;
