import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import { getArticlesByDate, getNextArticles } from '../../api-calls/Article';
import {
  Typography as T,
  Grid,
  Card,
  TextSection,
  ArticlesSection,
} from '../../components';
import * as S from './style.js';
import { getMonthNumber } from '../../helpers';
import { EXTERNAL } from '../../constants/nav-routes.js';
import { Helmet } from 'react-helmet';

const { Col, Row } = Grid;

const Articles = ({ articles }) => {
  const artLength = Math.ceil(articles.length / 6);
  const artSections = [];
  for (let i = 0; i < artLength; i++) {
    let items = articles.slice(i * 6, i * 6 + 6);
    artSections.push(items);
  }

  return artSections.map(
    (item) => !!item.length && <ArticlesSection key={item.id} articles={item} />
  );
};

const DatePage = () => {
  const [articles, setArticles] = useState([]);
  const [nextData, setNextData] = useState('');
  const [showItems, setShowItems] = useState(10);
  const [loading, setLoading] = useState(false);
  const [pageError, setPageError] = useState('');

  const { monthName, day } = useParams();

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const { error, data } = await getArticlesByDate(
          getMonthNumber(monthName),
          day
        );
        setArticles(data.results);
        setNextData(data.next);
        setLoading(false);
        if (error) {
          setPageError(error.message);
        }
      } catch (error) {
        setPageError(error.message);
        setLoading(false);
      }
    };
    getData();
  }, [day, monthName]);

  const displayDate = () => {
    return day >= 1
      ? `${monthName.charAt(0).toUpperCase() + monthName.slice(1)} ${day}`
      : monthName.charAt(0).toUpperCase() + monthName.slice(1);
  };

  const handleMore = async () => {
    setShowItems(showItems + 10);
    if (showItems % 90 && nextData) {
      try {
        setLoading(true);
        const { error, data } = await getNextArticles({
          nextUrl: nextData,
        });
        setArticles([...articles, ...data.results]);
        setNextData(data.next);
        setLoading(false);
        if (error) {
          setPageError(error.message);
        }
      } catch (error) {
        setPageError(error.message);
        setLoading(false);
      }
    }
  };

  const dateSubtitle = (day) => {
    if (day >= 1) {
      return `Timeline of people's history stories which occurred on ${displayDate()}`;
    } else {
      return `Timeline of people's history stories which occurred in ${displayDate()}, exact date unknown`;
    }
  };

  const mapLink = () => {
    if (day >= 1) {
      return `${EXTERNAL.MAP}?month=${getMonthNumber(monthName)}&day=${day}`;
    } else {
      return `${EXTERNAL.MAP}?month=${getMonthNumber(monthName)}&day=0`;
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${displayDate()?.substr(0, 43)} - WCH | Stories`}</title>
        <meta
          name="description"
          content={`${displayDate()} - Working Class History | Stories`}
        />
      </Helmet>
      <T.H1>{displayDate()}</T.H1>
      <Row>
        <Col w={[4, 12, 12]}>
          {pageError && (
            <T.P color="error" mt="8">
              {pageError}
            </T.P>
          )}
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 6]} mt="8">
          <Card bgColor="tertiaryMain" style={{ width: '100%' }}>
            <>
              <T.H4 color="white">{dateSubtitle(day)}</T.H4>
              {day && (
                <T.H5 color="white" mt="2">
                  Browse these stories on our{' '}
                  <T.MapLink color="white" external underline to={mapLink()}>
                    Map
                  </T.MapLink>
                </T.H5>
              )}
            </>
          </Card>
        </Col>

        {articles?.length && !loading ? (
          articles.slice(0, 5).map((item, i) => (
            <Col
              key={item.id}
              w={[4, 6, 6]}
              mt="8"
              jc={i % 2 ? 'flex-start' : 'flex-end'}
            >
              <TextSection {...item} />
            </Col>
          ))
        ) : (
          <Skeleton key="skeleton" loading={loading} active></Skeleton>
        )}
      </Row>
      {articles?.length ? (
        <Articles articles={articles.slice(5, showItems)} />
      ) : (
        <Row mt="8" mtM="5">
          <Col w={[4, 12, 12]}>
            <T.H4>more articles will come soon!</T.H4>
          </Col>
        </Row>
      )}
      {(articles.length > showItems || nextData) && (
        <S.LoadMore onClick={() => handleMore()}>
          <T.P underline>Load more...</T.P>
        </S.LoadMore>
      )}
    </>
  );
};

export default DatePage;
