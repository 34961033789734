import React from 'react';
import PropTypes from 'prop-types';
import * as S from './style';

const IFrame = ({ children, goBack, ...props }) => {
  return (
    <>
      <S.Wrapper {...props}>
        <S.Content>{children}</S.Content>
      </S.Wrapper>
    </>
  );
};

IFrame.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IFrame;
