const Youtube = ({ width, height, color, ...props }) => (
  <svg
    width={width || '32'}
    height={height || '24'}
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.7904 5.29913C31.7904 2.48663 29.7216 0.224131 27.1654 0.224131C23.7029 0.0622556 20.1716 -0.000244141 16.5629 -0.000244141H15.4379C11.8379 -0.000244141 8.30038 0.0622558 4.83788 0.224756C2.28788 0.224756 0.21913 2.49976 0.21913 5.31226C0.0628801 7.53663 -0.00336993 9.76163 0.000380067 11.9866C-0.00586993 14.2116 0.0649634 16.4387 0.21288 18.6679C0.21288 21.4804 2.28163 23.7616 4.83163 23.7616C8.46913 23.9304 12.2004 24.0054 15.9941 23.9991C19.7941 24.0116 23.515 23.9325 27.1566 23.7616C29.7129 23.7616 31.7816 21.4804 31.7816 18.6679C31.9316 16.4366 32.0004 14.2116 31.9941 11.9804C32.0083 9.75538 31.9404 7.5283 31.7904 5.29913ZM12.9379 18.1179V5.83663L22.0004 11.9741L12.9379 18.1179Z"
      fill={color || 'white'}
    />
  </svg>
);

export default Youtube;
