const Twitter = ({ width, height, color, ...props }) => (
  <svg
    width={width || '32'}
    height={height || '32'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31 6.84278C29.8747 7.33175 28.684 7.6539 27.4656 7.79903C28.746 7.04927 29.7089 5.85814 30.1737 4.44903C28.9632 5.1569 27.641 5.65358 26.2637 5.91778C25.6838 5.30999 24.9863 4.82647 24.2137 4.49662C23.4411 4.16677 22.6095 3.99748 21.7694 3.99903C18.3681 3.99903 15.6156 6.71153 15.6156 10.0553C15.6132 10.5204 15.6665 10.9841 15.7744 11.4365C13.3354 11.3222 10.9472 10.7 8.76249 9.60982C6.57777 8.51961 4.64468 6.98538 3.08687 5.10528C2.54036 6.02661 2.25133 7.07781 2.25 8.14903C2.25 10.249 3.34562 12.1053 5 13.1928C4.01983 13.1695 3.05974 12.9101 2.20125 12.4365V12.5115C2.20125 15.449 4.32625 17.8928 7.13875 18.449C6.60986 18.59 6.06485 18.6615 5.5175 18.6615C5.12911 18.6622 4.74161 18.6245 4.36062 18.549C5.1425 20.9553 7.41813 22.7053 10.1137 22.7553C7.92336 24.4434 5.23414 25.3557 2.46875 25.349C1.97789 25.3483 1.48748 25.3191 1 25.2615C3.81324 27.0579 7.0834 28.0081 10.4212 27.999C21.7562 27.999 27.9487 18.7678 27.9487 10.7615C27.9487 10.499 27.9419 10.2365 27.9294 9.98028C29.1313 9.12532 30.1711 8.06285 31 6.84278Z"
      fill={color || 'white'}
    />
  </svg>
);

export default Twitter;
