import styled from '@emotion/styled';

export const Wrapper = styled.div`
  /* set background image */
  /* background-image: url(${({ bgImage }) => bgImage}); */
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(${({ bgImage }) => bgImage});
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;
  /* set background color */
  background-color: ${({ theme }) => theme.colors.primaryNeutral};

  /* ensure height is always 100% */
  height: 100%;
  min-height: 100vh;

  /* set padding */
  padding: ${({ theme }) => theme.spacings[6]};
`;

export const ReadMore = styled.a``;
