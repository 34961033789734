import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  min-height: 100vh;
`;

export const Content = styled.main`
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;
