import { GENERAL } from '../constants/nav-routes.js';

export const getMonthName = (m) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return months[m - 1];
};

export const getMonthNumber = (m) => {
  const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];
  return months.indexOf(m.toLowerCase()) + 1;
};

export const formatDate = (year, month, day) => {
  if (year && month && day) {
    return `${day} ${getMonthName(month)} ${year}`;
  } else if (year && month) {
    return `${getMonthName(month)} ${year}`;
  } else return `${year}`;
};

export const pathForToday = () => {
  let today = new Date();
  return GENERAL.DATE.replace(
    ':monthName',
    today.toLocaleString('en-us', { month: 'long' }).toLowerCase()
  ).replace(':day', today.getDate());
};
