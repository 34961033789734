const TwitterShare = ({ width, height, color, ...props }) => (
  <svg
    width={width || '24'}
    height={height || '18'}
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.25 2.13233C22.406 2.49906 21.513 2.74067 20.5992 2.84952C21.5595 2.2872 22.2817 1.39385 22.6303 0.337019C21.7224 0.867922 20.7307 1.24043 19.6978 1.43858C19.2629 0.982737 18.7397 0.6201 18.1603 0.372712C17.5808 0.125325 16.9571 -0.00164484 16.327 -0.000480343C13.7761 -0.000480343 11.7117 2.03389 11.7117 4.54171C11.7099 4.89053 11.7499 5.23832 11.8308 5.57764C10.0016 5.49189 8.2104 5.02526 6.57187 4.20761C4.93333 3.38995 3.48351 2.23928 2.31516 0.829207C1.90527 1.5202 1.6885 2.3086 1.6875 3.11202C1.6875 4.68702 2.50922 6.07921 3.75 6.89483C3.01487 6.87738 2.29481 6.68282 1.65094 6.32764V6.38389C1.65094 8.58702 3.24469 10.4198 5.35406 10.837C4.9574 10.9428 4.54864 10.9963 4.13812 10.9964C3.84683 10.9969 3.5562 10.9686 3.27047 10.912C3.85687 12.7167 5.56359 14.0292 7.58531 14.0667C5.94252 15.3328 3.9256 16.0171 1.85156 16.012C1.48341 16.0115 1.11561 15.9896 0.75 15.9464C2.85993 17.2937 5.31255 18.0063 7.81594 17.9995C16.3172 17.9995 20.9616 11.0761 20.9616 5.07139C20.9616 4.87452 20.9564 4.67764 20.947 4.48546C21.8485 3.84423 22.6283 3.04738 23.25 2.13233Z"
      fill={color || '#120232'}
    />
  </svg>
);

export default TwitterShare;
