const Instagram = ({ width, height, color, ...props }) => (
  <svg
    width={width || '32'}
    height={height || '32'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.8331 4.3341C23.3789 4.33872 24.8601 4.95483 25.9531 6.04787C27.0461 7.14091 27.6623 8.62206 27.6669 10.1679V21.8341C27.6623 23.3799 27.0461 24.861 25.9531 25.9541C24.8601 27.0471 23.3789 27.6632 21.8331 27.6678H10.1669C8.62109 27.6632 7.13993 27.0471 6.04689 25.9541C4.95385 24.861 4.33774 23.3799 4.33312 21.8341V10.1679C4.33774 8.62206 4.95385 7.14091 6.04689 6.04787C7.13993 4.95483 8.62109 4.33872 10.1669 4.3341H21.8331ZM21.8331 2.00098H10.1669C5.675 2.00098 2 5.67598 2 10.1679V21.8341C2 26.326 5.675 30.001 10.1669 30.001H21.8331C26.325 30.001 30 26.326 30 21.8341V10.1679C30 5.67598 26.325 2.00098 21.8331 2.00098Z"
      fill={color || 'white'}
    />
    <path
      d="M23.583 10.167C23.2369 10.167 22.8985 10.0644 22.6108 9.87206C22.323 9.67977 22.0987 9.40646 21.9662 9.08669C21.8338 8.76692 21.7991 8.41505 21.8666 8.07558C21.9342 7.73612 22.1008 7.4243 22.3456 7.17956C22.5903 6.93481 22.9021 6.76814 23.2416 6.70062C23.5811 6.63309 23.9329 6.66775 24.2527 6.8002C24.5725 6.93266 24.8458 7.15696 25.0381 7.44474C25.2304 7.73253 25.333 8.07088 25.333 8.41699C25.3335 8.64694 25.2886 8.87473 25.2008 9.08727C25.113 9.29982 24.9841 9.49293 24.8215 9.65553C24.6589 9.81813 24.4658 9.94702 24.2533 10.0348C24.0407 10.1226 23.813 10.1675 23.583 10.167Z"
      fill={color || 'white'}
    />
    <path
      d="M16 11.3341C16.923 11.3341 17.8253 11.6078 18.5928 12.1206C19.3602 12.6334 19.9584 13.3623 20.3116 14.215C20.6649 15.0678 20.7573 16.0062 20.5772 16.9114C20.3971 17.8167 19.9527 18.6483 19.3 19.301C18.6473 19.9536 17.8157 20.3981 16.9105 20.5782C16.0052 20.7582 15.0668 20.6658 14.2141 20.3126C13.3613 19.9594 12.6324 19.3612 12.1196 18.5938C11.6068 17.8263 11.3331 16.924 11.3331 16.001C11.3345 14.7636 11.8266 13.5774 12.7015 12.7025C13.5764 11.8275 14.7627 11.3354 16 11.3341ZM16 9.00098C14.6155 9.00098 13.2622 9.41152 12.111 10.1807C10.9599 10.9499 10.0627 12.0431 9.53285 13.3222C9.00303 14.6013 8.86441 16.0087 9.13451 17.3666C9.4046 18.7245 10.0713 19.9718 11.0503 20.9507C12.0292 21.9297 13.2765 22.5964 14.6344 22.8665C15.9922 23.1366 17.3997 22.9979 18.6788 22.4681C19.9579 21.9383 21.0511 21.0411 21.8203 19.89C22.5895 18.7388 23 17.3854 23 16.001C23 14.1445 22.2625 12.364 20.9497 11.0512C19.637 9.73847 17.8565 9.00098 16 9.00098Z"
      fill={color || 'white'}
    />
  </svg>
);

export default Instagram;
